import "reflect-metadata";

import { Container } from "inversify";

import { authModuleContainer } from "./modules/auth/auth.ioc";
import { sessionModuleContainer } from "./modules/session/session.ioc";
import { clientModuleContainer } from "./modules/client/client.ioc";
import { eventsModuleContainer } from "./modules/events/events.ioc";
import { configModuleContainer } from "./modules/config/config.ioc";
import { websocketModuleContainer } from "./modules/websocket/websocket.ioc";
import { rbacModuleContainer } from "./modules/rbac/rbac.ioc";
import { loggerModuleContainer } from "./modules/logger/logger.ioc";
import { syncModuleContainer } from "./modules/sync/sync.ioc";
import { telemetryModuleContainer } from "./modules/telemetry/telemetry.ioc";
import { statsModuleContainer } from "./modules/stats/stats.ioc";
import { telemetrySdkClientModuleContainer } from "./modules/telemetrySdkClient/telemetrySdkClient.ioc";
import { withContainer } from "./backend/openApi.container";

export function getContainer(): Container {
    const container = new Container();
    container.load(authModuleContainer);
    container.load(configModuleContainer);
    container.load(sessionModuleContainer);
    container.load(clientModuleContainer);
    container.load(eventsModuleContainer);
    container.load(websocketModuleContainer);
    container.load(rbacModuleContainer);
    container.load(loggerModuleContainer);
    container.load(syncModuleContainer);
    container.load(telemetryModuleContainer);
    container.load(telemetrySdkClientModuleContainer);
    container.load(statsModuleContainer);
    withContainer(container);
    return container;
}
