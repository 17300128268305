import { injectable, inject } from "inversify";
import { TelemetryProcessor } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessor";
import { TelemetryEvent } from "~/modules/telemetry";
import { Logger, loggerFactoryRTTI, LoggerFactory, TelemetryLoggerName } from "~/modules/logger";
import { toSdkBackendEvents } from "./toSdkBackendEvent";
import { TelemetryBackendEvent } from "~/backend/generated/Telemetry/model/telemetryBackendEvent";
import { TelemetryService } from "~/backend/generated/Telemetry/api/telemetry.serviceInterface";
import { telemetryServiceRTTI } from "~/backend/backend.rtti";

const TELEMETRY_DISABLED_HTTP_STATUS_CODE = 409;
const MAX_NUMBER_OF_EVENTS_IN_BATCH = 50;

@injectable()
export class TwilioTelemetryProcessor implements TelemetryProcessor {
    private readonly logger: Logger;

    private readonly telemetryService: TelemetryService;

    private isTelemetryDisabled = false;

    constructor(
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory<TelemetryLoggerName>,
        @inject(telemetryServiceRTTI) telemetryService: TelemetryService
    ) {
        this.logger = getLogger(TelemetryLoggerName.TelemetryProcessor);
        this.telemetryService = telemetryService;
    }

    async processEvents(
        payloadType: string,
        groupName?: string,
        sessionData?: object,
        ...events: TelemetryEvent[]
    ): Promise<void> {
        if (this.isTelemetryDisabled) {
            this.logger.trace("Events not sent: telemetry disabled");
            return;
        }
        this.logger.debug("common attributes:", sessionData);
        const backendEvents = toSdkBackendEvents(payloadType, groupName, sessionData, ...events);

        if (backendEvents) {
            let backendEventsBatch;
            const arrayOfPromises = [];
            for (let i = 0; i < backendEvents.length; i += MAX_NUMBER_OF_EVENTS_IN_BATCH) {
                backendEventsBatch = backendEvents.slice(i, i + MAX_NUMBER_OF_EVENTS_IN_BATCH);
                arrayOfPromises.push(this.sendTelemetryEvents(...backendEventsBatch));
            }
            await Promise.all(arrayOfPromises);
        }
    }

    private async sendTelemetryEvents(...events: TelemetryBackendEvent[]): Promise<void> {
        this.logger.debug("Sending", events.length, "telemetry events");
        this.logger.trace("Events", events);

        try {
            await this.telemetryService.postTelemetryEvents({ events });
            this.logger.debug("Telemetry sent successfully");
        } catch (error) {
            const httpErrorCode = error.source?.status;
            if (httpErrorCode === TELEMETRY_DISABLED_HTTP_STATUS_CODE) {
                this.logger.warn("Telemetry is disabled for this account");
                this.isTelemetryDisabled = true;
                return;
            }

            throw error;
        }
    }
}
