import { interfaces } from "inversify";
import { SyncMap as SyncSdkSyncMap } from "twilio-sync";
import { SyncMapItem } from "~/modules/sync";
import { newableSyncMapItemRTTI } from "~/modules/sync/sync.rtti";
import { NewableSyncMapItem } from "~/modules/sync/SyncMapItem/NewableSyncMapItem";
import { FlexSdkError, ErrorCode } from "~/errors";

export async function getSyncMapItem(
    container: interfaces.Container,
    syncSdkSyncMap: SyncSdkSyncMap,
    key: string
): Promise<SyncMapItem> {
    try {
        const sdkSyncMapItem = await syncSdkSyncMap.get(key);
        const SyncMapItemConstructor = container.get<NewableSyncMapItem>(newableSyncMapItemRTTI);
        const item = new SyncMapItemConstructor(syncSdkSyncMap, sdkSyncMapItem);
        return item;
    } catch ({ code, message }) {
        throw new FlexSdkError(code || ErrorCode.SDK, message);
    }
}
