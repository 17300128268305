import { interfaces } from "inversify";

import { QueueStatsServiceImpl } from "./api/queueStats.service";
import { QueueStatsService } from "./api/queueStats.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<QueueStatsService>("QueueStatsService").to(QueueStatsServiceImpl).inSingletonScope();
    }
}
