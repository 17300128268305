import { ContainerModule, interfaces } from "inversify";
import { telemetrySdkClientRTTI } from "./telemetrySdkClient.rtti";
import { TelemetrySdkClient } from "./TelemetrySdkClient/TelemetrySdkClient";
import { TelemetrySDKSessionData } from "./TelemetrySdkClient/TelemetrySDKSessionData";
import { createTelemetryClient } from "~/modules/telemetry";

export const telemetrySdkClientModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<TelemetrySdkClient>(telemetrySdkClientRTTI)
        .toDynamicValue((context: interfaces.Context) => {
            return createTelemetryClient<TelemetrySDKSessionData>(context.container, "sdk-v1");
        })
        .inSingletonScope();
});
