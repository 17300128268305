import { ContainerModule, interfaces } from "inversify";
import { sessionRTTI, sessionSettingsRTTI } from "./session.rtti";
import { Session, SessionSettings } from "./Session/Session";
import { SessionImpl } from "./Session/SessionImpl";
import { getDefaultSessionSettings } from "./SessionSettings/defaultSessionSettings";

export const sessionModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<Session>(sessionRTTI).to(SessionImpl).inSingletonScope();
    bind<SessionSettings>(sessionSettingsRTTI).toConstantValue(getDefaultSessionSettings());
});
