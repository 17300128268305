
import { injectable, inject } from "inversify";
import { Logger, LoggerFactory, loggerFactoryRTTI, LoggerName } from "~/modules/logger";
import {
    SyncDocument,
    SyncList,
    SyncMap,
    SyncMapItem,
    SyncProvider,
    SyncProductId,
    syncProviderRTTI
} from "~/modules/sync";

import { SyncAdapter } from "./SyncAdapter";
import { InternalError } from "~/errors";

@injectable()
export class SyncAdapterImpl implements SyncAdapter {
    private readonly logger: Logger;

    private readonly syncProvider: SyncProvider;

    constructor(
        @inject(syncProviderRTTI) syncProvider: SyncProvider,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory
    ) {
        this.logger = getLogger(LoggerName.SyncAdapter);
        this.syncProvider = syncProvider;
    }

    




    async getSyncObjectByPath<T extends object>(
        path: string
    ): Promise<SyncDocument<T> | SyncList<T> | SyncMap | SyncMapItem<T>> {
        this.logger.trace("getSyncObjectByPath", path);
        
        const [_slash, productIdString, syncObjectType, uniqueNameOrSid, _items, itemKey] = path.split("/");

        const productId = productIdString as SyncProductId;
        if (!Object.values(SyncProductId).includes(productId)) {
            throw new InternalError(`Unknown product id: ${productIdString}`);
        }

        if (syncObjectType === "Documents") {
            return this.getDocument<T>(productId, uniqueNameOrSid);
        }
        if (syncObjectType === "Maps") {
            if (itemKey) {
                return this.getMapItem<T>(productId, uniqueNameOrSid, itemKey);
            }

            return this.getMap(productId, uniqueNameOrSid);
        }
        if (syncObjectType === "Lists") {
            return this.getList<T>(productId, uniqueNameOrSid);
        }

        throw new InternalError("Invalid Sync Object URL");
    }

    private getDocument<T extends object>(productId: SyncProductId, uniqueNameOrSid: string): Promise<SyncDocument<T>> {
        this.logger.trace("getDocument", productId, uniqueNameOrSid);
        
        throw new Error("Not implemented.");
    }

    private getList<T extends object>(productId: SyncProductId, uniqueNameOrSid: string): Promise<SyncList<T>> {
        this.logger.trace("getList", productId, uniqueNameOrSid);
        
        throw new Error("Not implemented.");
    }

    private getMap(productId: SyncProductId, uniqueNameOrSid: string): Promise<SyncMap> {
        this.logger.trace("getMap", productId, uniqueNameOrSid);
        
        throw new Error("Not implemented.");
    }

    private async getMapItem<T extends object>(
        productId: SyncProductId,
        uniqueNameOrSid: string,
        mapItemKey: string
    ): Promise<SyncMapItem<T>> {
        this.logger.trace("getMapItem", productId, uniqueNameOrSid, mapItemKey);
        const sync = await this.syncProvider(productId);
        const map = await sync.getMapById(uniqueNameOrSid);
        const item = await map.get(mapItemKey);

        return item as SyncMapItem<T>;
    }
}
