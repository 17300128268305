import { injectable } from "inversify";
import { AuthenticatorDataContainer } from "./AuthenticatorDataContainer";

@injectable()
export class AuthenticatorDataContainerImpl implements AuthenticatorDataContainer {
    private _accountSid: string;

    get accountSid(): string {
        return this._accountSid;
    }

    set accountSid(accountSid: string) {
        this._accountSid = accountSid;
    }
}
