import { ContainerModule, interfaces } from "inversify";
import {
    Client,
    ClientSettings,
    ClientUserSettings,
    ClientProvider,
    clientRTTI,
    clientProviderRTTI,
    clientSettingsRTTI
} from "~/modules/client";
import { ClientImpl } from "./Client/ClientImpl/ClientImpl";
import { createClient } from "./ClientProvider/createClient";
import { ClientSettingsStore } from "./ClientSettings/ClientSettingsStore";

export const clientModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<ClientSettings>(clientSettingsRTTI).to(ClientSettingsStore).inSingletonScope();

    bind<Client>(clientRTTI).to(ClientImpl).inSingletonScope();
    bind<ClientProvider>(clientProviderRTTI).toProvider<Client>((context: interfaces.Context) => {
        return (token: string, userSettings?: ClientUserSettings) => {
            return createClient(context.container, token, userSettings);
        };
    });
});
