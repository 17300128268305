import { interfaces } from "inversify";
import { SyncClient } from "twilio-sync";
import { SyncMap, MapMode } from "~/modules/sync";
import { newableSyncMapRTTI, syncMapItemProviderRTTI } from "~/modules/sync/sync.rtti";
import { NewableSyncMap } from "~/modules/sync/SyncMap/NewableSyncMap";
import { SyncMapItemProvider } from "~/modules/sync/SyncMapItemProvider/SyncMapItemProvider";
import { FlexSdkError, ErrorCode } from "~/errors";

export async function getSyncMap(
    container: interfaces.Container,
    syncClient: SyncClient,
    mapId: string,
    mapMode: MapMode
): Promise<SyncMap> {
    try {
        const sdkSyncMap = await syncClient.map({ id: mapId, mode: mapMode });
        const SyncMapConstructor = container.get<NewableSyncMap>(newableSyncMapRTTI);
        const syncMapItemProvider = container.get<SyncMapItemProvider>(syncMapItemProviderRTTI);

        const syncMap = new SyncMapConstructor(sdkSyncMap, syncMapItemProvider);
        return syncMap;
    } catch ({ code, message }) {
        throw new FlexSdkError(code || ErrorCode.SDK, message);
    }
}
