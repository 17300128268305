import { injectable, inject } from "inversify";
import { ClientSettings } from "~/modules/client";
import { SessionSettings, sessionSettingsRTTI } from "~/modules/session";
import { LoggerSettings, loggerSettingsRTTI } from "~/modules/logger";

@injectable()
export class ClientSettingsStore implements ClientSettings {
    private readonly _session: SessionSettings;

    private readonly _logger: LoggerSettings;

    
    
    private _region: string;

    private _appName: string;

    private _appVersion: string;

    constructor(
        @inject(sessionSettingsRTTI) session: SessionSettings,
        @inject(loggerSettingsRTTI) logger: LoggerSettings
    ) {
        this._session = session;
        this._logger = logger;
    }

    get session(): SessionSettings {
        return this._session;
    }

    get logger(): LoggerSettings {
        return this._logger;
    }

    get region(): string {
        return this._region;
    }

    set region(value: string) {
        this._region = value;
    }

    get appName(): string {
        return this._appName;
    }

    set appName(value: string) {
        this._appName = value;
    }

    get appVersion(): string {
        return this._appVersion;
    }

    set appVersion(value: string) {
        this._appVersion = value;
    }
}
