import mergeWith from "lodash.mergewith";
import { FlexSdkError, ErrorCode } from "~/errors";
import { assertObject } from "./assert";
import { DeepPartial } from "./DeepPartial";

export function mergeUserSettings<T>(settings: T, userSettings?: DeepPartial<T>): void {
    if (typeof userSettings !== "undefined") {
        assertObject(userSettings, "userSettings");
    }

    
    const customMerger = (
        value: undefined | Object,
        _srcValue: never,
        key: string,
        
        _object: object,
        
        _source: never
    ) => {
        const objectHasKey = Object.prototype.hasOwnProperty.call(_object, key);
        
        const objectHasGetterForKey = Object.prototype.hasOwnProperty.call(Object.getPrototypeOf(_object), key);
        if (!objectHasKey && !objectHasGetterForKey) {
            
            throw new FlexSdkError(ErrorCode.InvalidParams, `${key} is not a valid property`);
        }

        return undefined; 
    };

    mergeWith(settings, userSettings, customMerger);
}
