import { interfaces } from "inversify";
import { QueueStats } from "~/modules/stats";
import { NewableQueueStats } from "~/modules/stats/RealtimeStats/QueueStats/NewableQueueStats";
import { TasksByTimeFactory } from "~/modules/stats/RealtimeStats/QueueStats/TasksByTimeFactory/TasksByTimeFactory";
import { tasksByTimeFactoryRTTI, newableQueueStatsRTTI } from "~/modules/stats/stats.rtti";
import { queueStatsServiceRTTI } from "~/backend/backend.rtti";
import { QueueStatsService } from "~/backend/generated/RealtimeQueueStats/api/queueStats.serviceInterface";
import { ErrorCode, FlexSdkError } from "~/errors";

export async function getQueueStats(container: interfaces.Container, queueSid: string): Promise<QueueStats> {
    const getTasksByTime = container.get<TasksByTimeFactory>(tasksByTimeFactoryRTTI);
    const tasksByTime = getTasksByTime(queueSid);

    const QueueStatsConstructor = container.get<NewableQueueStats>(newableQueueStatsRTTI);
    const queueStatsService = container.get<QueueStatsService>(queueStatsServiceRTTI);

    try {
        const metadata = await queueStatsService.getMetadata(queueSid);
        const instance = new QueueStatsConstructor(queueSid, queueStatsService, tasksByTime, metadata);
        return instance;
    } catch (error) {
        if (error.code === ErrorCode.SyncNameNotFoundError) {
            throw new FlexSdkError(ErrorCode.NotFound, `Metadata statistics of the queue: ${queueSid}`, error);
        }
        throw error;
    }
}
