import { ContainerModule, interfaces } from "inversify";
import { newableTwilsockClientRTTI, twilsockRTTI, productIdRTTI } from "./websocket.rtti";
import { NewableTwilsockClient, TwilsockClient } from "./TwilsockClient/TwilsockClient";
import { Twilsock } from "./Twilsock/Twilsock";
import { TwilsockImpl } from "./Twilsock/TwilsockImpl";

export const websocketModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<NewableTwilsockClient>(newableTwilsockClientRTTI).toConstructor(TwilsockClient);
    bind<string>(productIdRTTI).toConstantValue("flex");
    bind<Twilsock>(twilsockRTTI).to(TwilsockImpl).inSingletonScope();
});
