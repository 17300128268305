import { injectable, inject, postConstruct } from "inversify";
import { Client, ClientConfigType, ClientEvent } from "~/modules/client";
import { Role } from "~/modules/auth";
import { accountConfigRTTI, AccountConfig } from "~/modules/config";
import { EventPublisher } from "~/modules/events";
import { Twilsock, twilsockRTTI, TwilsockEvent } from "~/modules/websocket";
import { Session, sessionRTTI } from "~/modules/session";
import { Logger, loggerFactoryRTTI, LoggerFactory, LoggerName } from "~/modules/logger";
import { TelemetryClient, TelemetryClientFactory, telemetryClientFactoryRTTI } from "~/modules/telemetry";
import {
    telemetrySdkClientRTTI,
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkClient,
    TelemetrySdkEventName,
    TelemetrySdkEventSource
} from "~/modules/telemetrySdkClient";
import { RealtimeStats, realtimeStatsRTTI } from "~/modules/stats";

@injectable()
export class ClientImpl extends EventPublisher<ClientEvent> implements Client {
    readonly #session: Session;

    readonly #connection: Twilsock;

    readonly #logger: Logger;

    readonly #telemetryClientFactory: TelemetryClientFactory<any>; 

    public readonly config: ClientConfigType;

    readonly #telemetrySdkClient: TelemetrySdkClient;

    readonly realtimeStats: RealtimeStats;

    constructor(
        @inject(sessionRTTI) session: Session,
        @inject(twilsockRTTI) connection: Twilsock,
        @inject(accountConfigRTTI) accountConfig: AccountConfig,
        @inject(telemetryClientFactoryRTTI) telemetryClientFactory: TelemetryClientFactory<any>, 
        @inject(realtimeStatsRTTI) realtimeStats: RealtimeStats,
        @inject(telemetrySdkClientRTTI) telemetrySdkClient: TelemetrySdkClient,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory
    ) {
        super();
        this.#session = session;
        this.#connection = connection;
        this.config = {
            account: accountConfig
        };
        this.#telemetryClientFactory = telemetryClientFactory;
        this.realtimeStats = realtimeStats;
        this.#telemetrySdkClient = telemetrySdkClient;
        this.#logger = getLogger(LoggerName.Client);
    }

    @postConstruct()
    setupProxies() {
        this.proxyEvent(this.#connection, TwilsockEvent.TokenAboutToExpire, ClientEvent.TokenAboutToExpire);
        this.proxyEvent(this.#connection, TwilsockEvent.TokenExpired, ClientEvent.TokenExpired);
        this.proxyEvent(this.#connection, TwilsockEvent.ConnectionError, ClientEvent.ConnectionLost);
        this.proxyEvent(this.#connection, TwilsockEvent.Connected, ClientEvent.ConnectionRestored);
        this.proxyEvent(this.#connection, TwilsockEvent.Disconnected, ClientEvent.Disconnected);
    }

    async updateToken(token: string) {
        await this.#session.updateToken(token);
    }

    #sendDestroyEvent = async () => {
        try {
            const telemetrySdkClient = this.#telemetrySdkClient;
            const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
            await group.addEvents({
                eventName: TelemetrySdkEventName.ClientDestroyed,
                eventSource: TelemetrySdkEventSource.Client
            });
        } catch (e) {
            this.#logger.error("Failed to send telemetry destroy event", e);
        }
    };

    async destroy() {
        await this.#sendDestroyEvent();
        this.#logger.debug("client log out");
        await this.#session.destroy();
        this.removeAllListeners();
    }

    get roles(): Array<Role> {
        return this.#session.roles;
    }

    get token(): string {
        return this.#session.token;
    }

    createTelemetryClient<U extends object>(name: string): TelemetryClient<U> {
        return this.#telemetryClientFactory(name);
    }
}
