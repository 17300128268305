import cloneDeep from "lodash.clonedeep";
import { SessionSettings } from "~/modules/session";

const defaultSessionSettings: SessionSettings = {
    autoUpdateToken: true
};

export function getDefaultSessionSettings(): SessionSettings {
    return cloneDeep(defaultSessionSettings);
}
