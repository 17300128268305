import { interfaces } from "inversify";
import { RuntimeDomainOption, AccountSidOption, PublicConfig } from "~/modules/config";
import { InternalError } from "~/errors";
import { XOR } from "~/utils/utilityTypes";
import { ConfigurationService } from "~/backend/generated/Configuration/api/configuration.serviceInterface";
import { configurationServiceRTTI } from "~/backend/backend.rtti";
import { mapRootKeysToCamelCase } from "~/utils/mapKeys";

const TWILIO_DOMAIN = ".twil.io";
const RUNTIME_DOMAIN_REGEXP = /^([a-z]+-[a-z]+-\d+)$/;

function sanitizeRuntimeDomain(domain: string): string {
    let sanitizedDomain = domain.trim();

    if (sanitizedDomain.endsWith("/")) {
        sanitizedDomain = sanitizedDomain.replace(/\/$/, "");
    }

    if (!sanitizedDomain.endsWith(TWILIO_DOMAIN)) {
        const parsedRuntimeDomain = sanitizedDomain.match(RUNTIME_DOMAIN_REGEXP);
        if (parsedRuntimeDomain && parsedRuntimeDomain[1]) {
            sanitizedDomain += TWILIO_DOMAIN;
        }
    }

    return sanitizedDomain;
}

function isAccountSidOption(option: XOR<AccountSidOption, RuntimeDomainOption>): option is AccountSidOption {
    return !!(option as AccountSidOption).accountSid;
}

function getPublicConfigQueryParam(
    option: XOR<AccountSidOption, RuntimeDomainOption>
): { paramName: "AccountSid" | "RuntimeDomain"; paramValue: string } {
    if (isAccountSidOption(option)) {
        const accountSid = (option as AccountSidOption).accountSid;
        return { paramName: "AccountSid", paramValue: accountSid };
    }

    const runtimeDomain = (option as RuntimeDomainOption).runtimeDomain;
    const sanitizedDomain = sanitizeRuntimeDomain(runtimeDomain);
    return { paramName: "RuntimeDomain", paramValue: sanitizedDomain };
}

export async function getPublicConfig(
    container: interfaces.Container,
    option: XOR<AccountSidOption, RuntimeDomainOption>
): Promise<PublicConfig> {
    const configurationService = container.get<ConfigurationService>(configurationServiceRTTI);

    const queryParam = getPublicConfigQueryParam(option);
    const responseData = await configurationService.fetchPublicConfiguration(
        queryParam.paramName,
        queryParam.paramValue
    );
    if (!(responseData && responseData.configurations && responseData.configurations.length)) {
        throw new InternalError("Invalid response from public configuration endpoint");
    }
    return mapRootKeysToCamelCase(responseData.configurations[0]) as PublicConfig;
}
