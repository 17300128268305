import { injectable, inject } from "inversify";
import { Role, AuthenticatorFactory, authenticatorFactoryRTTI } from "~/modules/auth";
import { AccountConfigProvider, accountConfigProviderRTTI, AccountConfig } from "~/modules/config";
import { sessionSettingsRTTI, Session, SessionSettings } from "~/modules/session";
import { Twilsock, twilsockRTTI, TwilsockEvent } from "~/modules/websocket";
import { InternalError } from "~/errors";
import { Logger, LoggerFactory, loggerFactoryRTTI, LoggerName } from "~/modules/logger";

@injectable()
export class SessionImpl implements Session {
    private accountConfig: AccountConfig;

    private readonly authFactory: AuthenticatorFactory;

    private _token: string;

    private readonly connection: Twilsock;

    private readonly settings: SessionSettings;

    private readonly accountConfigProvider: AccountConfigProvider;

    private _roles: Array<Role> = [];

    private isActive: boolean = true;

    private readonly logger: Logger;

    private needsToAutoUpdateToken: boolean;

    constructor(
        @inject(twilsockRTTI) connection: Twilsock,
        @inject(sessionSettingsRTTI) settings: SessionSettings,
        @inject(authenticatorFactoryRTTI) authFactory: AuthenticatorFactory,
        @inject(accountConfigProviderRTTI) accountConfigProvider: AccountConfigProvider,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory
    ) {
        this.connection = connection;
        this.settings = settings;
        this.accountConfigProvider = accountConfigProvider;
        this.authFactory = authFactory;
        this.logger = getLogger(LoggerName.Session);
        this.logger.debug("Session constructed");
    }

    async init(token: string): Promise<void> {
        this.logger.debug("will initialize session with token: ", token);
        this.logger.debug("will update token: ", this.settings.autoUpdateToken);

        this._token = token;
        await this.connection.connect(token);
        if (this.settings.autoUpdateToken) {
            this.connection.on(TwilsockEvent.TokenAboutToExpire, this.handleTokenAboutToExpire);
        }

        
        this.accountConfig = await this.accountConfigProvider();

        
        const accountSid = this.accountConfig.get("accountSid");

        if (this.needsToAutoUpdateToken) {
            await this.autoUpdateToken();
        }

        const auth = this.authFactory(accountSid);
        const tokenData = await auth.validateToken(this._token);
        this._roles = tokenData.roles;

        return Promise.resolve();
    }

    async updateToken(token: string): Promise<void> {
        await this.connection.updateToken(token);
        this._token = token;
        this.logger.debug("new token set");
    }

    private readonly handleTokenAboutToExpire = async () => {
        if (this.accountConfig) {
            await this.autoUpdateToken();
        } else {
            this.needsToAutoUpdateToken = true;
        }
    };

    private async autoUpdateToken() {
        const accountSid = this.accountConfig.get("accountSid");
        if (!accountSid) {
            throw new InternalError("Account sid not set");
        }

        this.logger.debug("updating token");
        const auth = this.authFactory(accountSid);
        try {
            const newToken = await auth.refreshToken(this.token);
            if (!this.isActive) {
                this.logger.trace("autoUpdateToken, session destroyed after refreshToken");
                return;
            }

            await this.updateToken(newToken);
            this.logger.info("token auto-updated");
        } catch (e) {
            this.logger.error("Failed to update token", e);
        }
    }

    async destroy() {
        this.isActive = false;
        this.connection.removeListener(TwilsockEvent.TokenAboutToExpire, this.handleTokenAboutToExpire);
        await this.connection.destroy();
    }

    get token(): string {
        return this._token;
    }

    get roles(): Array<Role> {
        return this._roles;
    }
}
