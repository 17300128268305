import { version as packageVersion } from "package.json";
import { getContainer } from "~/ioc.config";
import { publicConfigProviderRTTI, PublicConfigProvider } from "~/modules/config";
import { clientProviderRTTI, ClientProvider } from "~/modules/client";
import { authenticatorFactoryRTTI, twilioIdpUrlRTTI, AuthenticatorFactory, TwilioIdpUrlFunction } from "~/modules/auth";
import { LoggerFactory, loggerFactoryRTTI } from "~/modules/logger";

export type { SessionSettings } from "./modules/session";
export type { DeepPartial } from "./utils/DeepPartial";
export type { Without, XOR } from "./utils/utilityTypes";
export type {
    AccountConfig,
    AccountConfigKeys,
    AccountConfigData,
    IntegrationsConfig,
    OutboundFlows,
    SkillDefinition,
    QueueStatsConfiguration,
    PublicConfig,
    PublicConfigAttributes,
    PublicConfigProvider,
    AccountSidOption,
    RuntimeDomainOption,
    TaskQueue,
    WorkerChannel,
    DefaultQueueStatsConfiguration,
    QueueConfiguration,
    QueueChannelConfiguration
} from "./modules/config";
export type { Logger, LoggerFactory, LoggerSettings, LoggerLevel, LoggerLevelNumbers } from "./modules/logger";
export { LoggerName } from "./modules/logger";
export type { Client, ClientProvider, ClientSettings, ClientUserSettings, ClientConfigType } from "./modules/client";
export { ClientEvent } from "./modules/client";
export type {
    Authenticator,
    AuthenticatorFactory,
    TwilioIdpUrlFunction,
    IdpConfig,
    Role,
    TokenData
} from "./modules/auth";
export type { EventPublisher } from "./modules/events";
export type { Subscribable } from "./modules/sync";
export type { TelemetryEvent, TelemetryErrorEvent, TelemetryEventGroup, TelemetryClient } from "./modules/telemetry";

export type {
    RealtimeStats,
    QueueStats,
    TaskChannelStats,
    CurrentTasksStats,
    HistoricalTasksStats,
    TasksByTime,
    WorkerStats,
    ActivityStats
} from "./modules/stats";
export { TimeSpan } from "./modules/stats";

const container = getContainer();




export const auth = container.get<AuthenticatorFactory>(authenticatorFactoryRTTI);




export const getLogger = container.get<LoggerFactory<string>>(loggerFactoryRTTI);




export const getTwilioIdpUrl = container.get<TwilioIdpUrlFunction>(twilioIdpUrlRTTI);




export const getPublicConfig = container.get<PublicConfigProvider>(publicConfigProviderRTTI);




export const createClient = container.get<ClientProvider>(clientProviderRTTI);




export const version: string = packageVersion;

export { ErrorCode, FlexSdkError } from "./errors";
