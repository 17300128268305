import { SyncClient } from "twilio-sync";

export { SyncClient };

export type SyncOptions = {
    region?: string;
};

export enum SyncClientEvent {
    ConnectionStateChanged = "connectionStateChanged"
}

export enum SyncClientState {
    Connected = "connected",
    Error = "error",
    Disconnected = "disconnected",
    Denied = "denied"
}

export interface NewableSyncClient {
    new (token: string, options: SyncOptions): SyncClient;
}

export interface SyncDocument<T extends object = object> {
    
}

export interface SyncList<T extends object = object> {
    
}
