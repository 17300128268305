import { injectable, inject } from "inversify";
import loglevel from "loglevel";
import { version } from "package.json";
import { EventPublisher } from "~/modules/events";
import {
    TwilsockClient,
    NewableTwilsockClient,
    TwilsockClientEvent
} from "~/modules/websocket/TwilsockClient/TwilsockClient";
import { newableTwilsockClientRTTI, productIdRTTI } from "~/modules/websocket/websocket.rtti";
import { Headers, Twilsock, TwilsockResult, TwilsockEvent } from "~/modules/websocket";
import { ErrorCode, FlexSdkError, throwFlexSdkError, InternalError } from "~/errors";
import { Logger, loggerFactoryRTTI, LoggerFactory, LoggerName, loglevelMethodName } from "~/modules/logger";
import { environmentConfigRTTI, EnvironmentConfig } from "~/modules/config";
import { clientSettingsRTTI, ClientUserSettings } from "~/modules/client";
import { retry } from "~/utils/retry";

const FLEX_SDK_NAME = "flex-sdk";
const FLEX_SDK_PLATFORM = "JS";

@injectable()
export class TwilsockImpl extends EventPublisher<TwilsockEvent> implements Twilsock {
    private readonly productId: string;

    private readonly NewableTwilsockClient: NewableTwilsockClient;

    private twilsockClient?: TwilsockClient;

    private readonly logger: Logger;

    private readonly environmentConfig: EnvironmentConfig;

    private readonly clientSettings: ClientUserSettings;

    constructor(
        @inject(newableTwilsockClientRTTI) TC: NewableTwilsockClient,
        @inject(productIdRTTI) productId: string,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory,
        @inject(environmentConfigRTTI) environmentConfig: EnvironmentConfig,
        @inject(clientSettingsRTTI) clientSettings: ClientUserSettings
    ) {
        super();
        this.NewableTwilsockClient = TC;
        this.productId = productId;
        this.logger = getLogger(LoggerName.Twilsock);
        this.logger.debug("Twilsock constructed");
        this.environmentConfig = environmentConfig;
        this.clientSettings = clientSettings;
    }

    async connect(token: string): Promise<void> {
        if (this.twilsockClient) {
            throw new InternalError("Twilsock connection already exists");
        }
        const clientOptions = {
            region: this.clientSettings.region || this.environmentConfig.region,
            clientMetadata: {
                type: FLEX_SDK_NAME,
                sdk: FLEX_SDK_PLATFORM,
                sdkv: version,
                app: this.clientSettings.appName,
                appv: this.clientSettings.appVersion
            }
        };
        this.twilsockClient = new this.NewableTwilsockClient(token, this.productId, clientOptions);
        this.proxyEventsFromTwilsockClient();
        this.proxyLogsFromTwilsockClient();
        this.twilsockClient.connect();
        await this.waitUntilConnectedOrRejected();
    }

    private proxyEventsFromTwilsockClient() {
        this.proxyEvent(this.getRawTwilsockClient(), TwilsockClientEvent.TokenExpired, TwilsockEvent.TokenExpired);
        this.proxyEvent(
            this.getRawTwilsockClient(),
            TwilsockClientEvent.TokenAboutToExpire,
            TwilsockEvent.TokenAboutToExpire
        );
        this.proxyEvent(this.getRawTwilsockClient(), TwilsockClientEvent.StateChanged, TwilsockEvent.StateChanged);
        this.proxyEvent(this.getRawTwilsockClient(), TwilsockClientEvent.Connected, TwilsockEvent.Connected);
        this.proxyEvent(this.getRawTwilsockClient(), TwilsockClientEvent.Disconnected, TwilsockEvent.Disconnected);
        this.listenAndEmitConnectionError();
    }

    private proxyLogsFromTwilsockClient() {
        const twilsockLoglevel = loglevel.getLogger("twilsock");
        twilsockLoglevel.methodFactory = (methodName: loglevelMethodName) => (...messages: unknown[]) => {
            



            return this.logger[methodName](...messages);
        };
        twilsockLoglevel.setLevel("trace");
    }

    private listenAndEmitConnectionError(): void {
        this.getRawTwilsockClient().on(TwilsockClientEvent.ConnectionError, (error) => {
            const errorCode = error.errorCode || ErrorCode.TwilsockConnectionError;
            const flexError = new FlexSdkError(errorCode, error?.message);
            super.emit(TwilsockEvent.ConnectionError, flexError);
        });
    }

    async updateToken(token: string): Promise<void> {
        if (!this.twilsockClient) {
            throw new FlexSdkError(ErrorCode.InvalidState, "no twilsock client");
        }

        try {
            await this.twilsockClient.updateToken(token);
            this.emit(TwilsockEvent.TokenUpdated, token);
        } catch (error) {
            throwFlexSdkError(error);
        }
    }

    private waitUntilConnectedOrRejected(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.getRawTwilsockClient().isConnected) {
                resolve();
                return;
            }

            const successHandler = () => {
                return resolve();
            };

            const connectionErrorHandler = (error: FlexSdkError) => {
                return reject(error);
            };

            const removeConnectionListeners = () => {
                this.removeListener(TwilsockEvent.Connected, successHandler);
                this.removeListener(TwilsockEvent.ConnectionError, connectionErrorHandler);
            };

            this.on(TwilsockEvent.Connected, () => {
                removeConnectionListeners();
                successHandler();
            });
            this.on(TwilsockEvent.ConnectionError, (error: FlexSdkError) => {
                removeConnectionListeners();
                connectionErrorHandler(error);
            });
        });
    }

    getRawTwilsockClient() {
        if (!this.twilsockClient) {
            throw new InternalError("Twilsock hasn't been initialized");
        }
        return this.twilsockClient;
    }

    async post<T>(url: string, headers: Headers, body: object): Promise<TwilsockResult<T>> {
        try {
            return await retry<TwilsockResult<T>>(
                () => this.getRawTwilsockClient().post(url, headers, body),
                this.logger
            );
        } catch (error) {
            const code: number = error.body?.code || ErrorCode.SDK;
            const message: string = error.body?.message || error.message;

            throw new FlexSdkError(code, message, error);
        }
    }

    async destroy() {
        if (!this.twilsockClient) {
            return;
        }
        const twilsockClient = this.twilsockClient;
        const connectionDestroyed = new Promise((resolve) => {
            twilsockClient.on(TwilsockClientEvent.Disconnected, resolve);
        });
        await twilsockClient.disconnect();
        await connectionDestroyed;
        delete this.twilsockClient;
        this.removeAllListeners();
    }
}
