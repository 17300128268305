import { interfaces } from "inversify";

import { Role } from "~/modules/auth";
import { Session, sessionRTTI } from "~/modules/session";
import { FlexSdkError, ErrorCode } from "~/errors";

export const requiredRole = (container: interfaces.Container) => (role: Role) => {
    return function decorator(target: unknown, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        const session = container.get<Session>(sessionRTTI);

        
        descriptor.value = function wrappedMethod(...args: Array<unknown>) {
            const currentRoles = session.roles;
            if (!currentRoles.includes(role)) {
                const wrongRole: string = currentRoles.length ? currentRoles.toString() : "no role";
                throw new FlexSdkError(ErrorCode.Forbidden, `Expected the role ${role}, but got ${wrongRole} instead.`);
            }

            const result = originalMethod.apply(this, args);
            return result;
        };
    };
};
