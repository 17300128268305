import { injectable, inject } from "inversify";
import { TelemetryClient, TelemetryEvent, TelemetryEventGroup, TelemetryErrorEvent } from "~/modules/telemetry";
import { TelemetryProcessor } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessor";
import { telemetryProcessorRTTI } from "~/modules/telemetry/telemetry.rtti";

@injectable()
export class TelemetryEventGroupImpl<T> implements TelemetryEventGroup<T> {
    name?: string;

    telemetryClient: TelemetryClient;

    private readonly processor: TelemetryProcessor;

    constructor(@inject(telemetryProcessorRTTI) processor: TelemetryProcessor) {
        this.processor = processor;
    }

    async addEvents(...events: (T & TelemetryEvent)[]): Promise<void> {
        return this.processor.processEvents(
            this.telemetryClient.name,
            this.name,
            this.telemetryClient.sessionData,
            ...events
        );
    }

    async addErrorEvent(errorEvent: TelemetryErrorEvent): Promise<void> {
        return this.processor.processEvents("error-v1", this.name, undefined, errorEvent);
    }
}
