import { LogLevelNumbers, MethodFactory } from "loglevel";
import { LogEntry } from "~/modules/logger";

export const setContext = (originalFactory: MethodFactory) => (
    methodName: string,
    logLevel: LogLevelNumbers,
    loggerName: string
) => {
    return (logEntry: LogEntry, ...messages: unknown[]) => {
        
        

        const method = originalFactory(methodName, logLevel, loggerName);
        return method(logEntry, ...messages);
    };
};
