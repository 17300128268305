import { injectable, inject } from "inversify";
import { EventPublisher } from "~/modules/events";
import { SyncMap, MapMode, Sync, SyncProductId } from "~/modules/sync";
import { NewableSyncClient, SyncClient, SyncClientEvent, SyncClientState } from "~/modules/sync/SyncClient/SyncClient";
import { newableSyncClientRTTI, syncMapProviderRTTI } from "~/modules/sync/sync.rtti";
import { FlexSdkError, ErrorCode, throwFlexSdkError, InternalError } from "~/errors";
import { Logger, LoggerFactory, loggerFactoryRTTI, LoggerName } from "~/modules/logger";
import { twilsockRTTI, Twilsock, TwilsockEvent } from "~/modules/websocket";
import { environmentConfigRTTI, EnvironmentConfig } from "~/modules/config";
import { clientSettingsRTTI, ClientUserSettings } from "~/modules/client";
import { SyncMapProvider } from "~/modules/sync/SyncMapProvider/SyncMapProvider";
import { SyncEvent } from "./SyncEvent";

@injectable()
export class SyncImpl extends EventPublisher<SyncEvent> implements Sync {
    private syncClient: SyncClient;

    private readonly NewableSyncClient: NewableSyncClient;

    private readonly logger: Logger;

    private readonly twilsock: Twilsock;

    private readonly environmentConfig: EnvironmentConfig;

    private readonly clientSettings: ClientUserSettings;

    private readonly syncMapProvider: SyncMapProvider;

    constructor(
        @inject(newableSyncClientRTTI) newableSyncClient: NewableSyncClient,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory,
        @inject(twilsockRTTI) twilsock: Twilsock,
        @inject(environmentConfigRTTI) environmentConfig: EnvironmentConfig,
        @inject(clientSettingsRTTI) clientSettings: ClientUserSettings,
        @inject(syncMapProviderRTTI) syncMapProvider: SyncMapProvider
    ) {
        super();
        this.NewableSyncClient = newableSyncClient;
        this.logger = getLogger(LoggerName.Sync);
        this.twilsock = twilsock;
        this.environmentConfig = environmentConfig;
        this.clientSettings = clientSettings;
        this.syncMapProvider = syncMapProvider;
    }

    public isConnected(): boolean {
        return this.syncClient?.connectionState === SyncClientState.Connected;
    }

    async connect(token: string, productId: SyncProductId): Promise<void> {
        if (this.syncClient) {
            return;
        }

        const clientOptions = {
            
            
            region: this.clientSettings.region || this.environmentConfig.region,
            productId
        };
        this.syncClient = new this.NewableSyncClient(token, clientOptions);
        await this.waitUntilConnectedOrRejected();
        this.listenOnTokenUpdateEvent();
        this.listenOnDisconnectEvent();
    }

    private async waitUntilConnectedOrRejected(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.syncClient.connectionState === SyncClientState.Connected) {
                resolve();
            }

            const connectionStateHandler = (newState: SyncClientState) => {
                this.logger.debug(`Connection state changed: ${newState}`);
                if (newState === SyncClientState.Connected) {
                    resolve();
                }

                if (newState === SyncClientState.Error) {
                    this.syncClient.removeAllListeners();
                }

                if ([SyncClientState.Error, SyncClientState.Disconnected, SyncClientState.Denied].includes(newState)) {
                    reject(new FlexSdkError(ErrorCode.SyncConnectionError));
                }
            };

            this.syncClient.on(SyncClientEvent.ConnectionStateChanged, connectionStateHandler);
        });
    }

    private listenOnTokenUpdateEvent(): void {
        const tokenUpdateHandler = async (token: string) => {
            try {
                await this.syncClient.updateToken(token);
            } catch (error) {
                throwFlexSdkError(error);
            }
        };
        this.twilsock.on(TwilsockEvent.TokenUpdated, tokenUpdateHandler);
    }

    private listenOnDisconnectEvent(): void {
        const disconnectHandler = async () => {
            try {
                await this.destroy();
            } catch (error) {
                throwFlexSdkError(error);
            }
        };
        this.twilsock.on(TwilsockEvent.Disconnected, disconnectHandler);
    }

    async getMapById(mapId: string, mapMode: MapMode = MapMode.OpenExisting): Promise<SyncMap> {
        if (!this.syncClient) {
            throw new InternalError("Sync client hasn't been initialized");
        }

        return this.syncMapProvider(this.syncClient, mapId, mapMode);
    }

    async destroy(): Promise<void> {
        this.emit(SyncEvent.Destroyed);
        if (!this.syncClient) {
            return;
        }
        await this.syncClient.shutdown();
        this.syncClient.removeAllListeners();
        this.logger.debug("Sync client destroyed");
    }
}
