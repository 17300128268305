


export enum ClientEvent {
    TokenExpired = "tokenExpired",
    TokenAboutToExpire = "tokenAboutToExpire",
    ConnectionLost = "connectionLost",
    ConnectionRestored = "connectionRestored",
    Disconnected = "disconnected"
}
