import { interfaces } from "inversify";
import { SyncClient } from "twilio-sync";
import { Client, ClientSettings, ClientUserSettings, clientRTTI, clientSettingsRTTI } from "~/modules/client";
import { Session, sessionRTTI } from "~/modules/session";
import { mergeUserSettings } from "~/utils/mergeUserSettings";
import { assertNotEmptyString } from "~/utils/assert";
import {
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkEventName,
    TelemetrySdkEventSource,
    TelemetrySdkClient,
    telemetrySdkClientRTTI,
    SDKDependency
} from "~/modules/telemetrySdkClient";

import { Logger, LoggerFactory, loggerFactoryRTTI, LoggerName } from "~/modules/logger";
import { FlexSdkError, ErrorCode, throwFlexSdkError } from "~/errors";

const sendClientInitEvent = async (logger: Logger, telemetrySdkClient: TelemetrySdkClient, durationInMs: number) => {
    try {
        const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
        await group.addEvents({
            eventName: TelemetrySdkEventName.ClientInitialized,
            eventSource: TelemetrySdkEventSource.Client,
            durationMs: durationInMs
        });
    } catch (e) {
        logger.error("Failed to send client init event", e);
    }
};

export async function createClient(
    container: interfaces.Container,
    token: string,
    userSettings?: ClientUserSettings
): Promise<Client> {
    const t0Ms = Date.now();

    assertNotEmptyString(token, "token");
    const clientSettings = container.get<ClientSettings>(clientSettingsRTTI);
    const getLogger = container.get<LoggerFactory>(loggerFactoryRTTI);
    const logger = getLogger(LoggerName.Client);

    mergeUserSettings(clientSettings, userSettings);

    const client = container.get<Client>(clientRTTI);
    const session = container.get<Session>(sessionRTTI);

    try {
        await session.init(token);
    } catch (err) {
        
        
        if (err.code === 2002 || err.code === 20003) {
            
            throw new FlexSdkError(ErrorCode.Forbidden, "Insufficient permissions");
        } else {
            throwFlexSdkError(err);
        }
    }

    try {
        const telemetrySdkClient = container.get<TelemetrySdkClient>(telemetrySdkClientRTTI);

        await telemetrySdkClient.setSessionData({
            dependencies: {
                [SDKDependency.TwilioSync]: SyncClient.version,
                [SDKDependency.Twilsock]: "default"
            }
        });

        const durationMs = Date.now() - t0Ms;
        
        await sendClientInitEvent(logger, telemetrySdkClient, durationMs);
    } catch (e) {
        logger.error("Failed to set session data for telemetry", e);
    }

    return client;
}
