import { injectable, inject } from "inversify";
import { throwFlexSdkErrorFromResponse } from "~/errors";
import { sessionRTTI, Session } from "~/modules/session";
import { HttpAdapter } from "./HttpAdapter";
import { EnvironmentConfig, environmentConfigRTTI } from "~/modules/config";
import { mapRootKeysToCamelCase, mapRootKeysToSnakeCase } from "~/utils/mapKeys";

@injectable()
export class HttpAdapterImpl implements HttpAdapter {
    private readonly session: Session;

    private readonly envConfig: EnvironmentConfig;

    constructor(@inject(sessionRTTI) session: Session, @inject(environmentConfigRTTI) envConfig: EnvironmentConfig) {
        this.session = session;
        this.envConfig = envConfig;
    }

    public get<T>(url: string, authMethod?: string) {
        return this.performNetworkCall<T>(url, "GET", authMethod);
    }

    public post<T>(url: string, authMethod?: string, body?: {}) {
        return this.performNetworkCall<T>(url, "POST", authMethod, body);
    }

    public put<T>(url: string, authMethod?: string, body?: {}) {
        return this.performNetworkCall<T>(url, "PUT", authMethod, body);
    }

    public delete<T>(url: string, authMethod?: string) {
        return this.performNetworkCall<T>(url, "DELETE", authMethod);
    }

    private getRequestData(method: string, authMethod?: string, body?: object) {
        let headers: Headers | undefined;
        if (authMethod === "jwe") {
            headers = new Headers({
                Authorization: `Basic ${btoa(`token:${this.session.token}`)})`,
                "Content-Type": "application/json"
            });
        }
        return { headers, method, body: JSON.stringify(body) };
    }

    private getEnvironmentSpecificUrl(url: string) {
        let urlEnvSpecifier = "";
        const region = this.envConfig.region || "";
        if (region.startsWith("dev-")) {
            urlEnvSpecifier = ".dev";
        } else if (region.startsWith("stage-")) {
            urlEnvSpecifier = ".stage";
        }
        return url.replace("[environment]", urlEnvSpecifier);
    }

    private mapBody(body: object | undefined) {
        if (body) {
            return mapRootKeysToSnakeCase(body);
        }
        return body;
    }

    private async performNetworkCall<T>(url: string, method: string, authMethod?: string, body?: object): Promise<T> {
        const environmentSpecificUrl = this.getEnvironmentSpecificUrl(url);
        const response: Response = await fetch(
            environmentSpecificUrl,
            this.getRequestData(method, authMethod, this.mapBody(body))
        );
        if (!response.ok) {
            await throwFlexSdkErrorFromResponse(response);
        }
        const result = await response.json();

        return mapRootKeysToCamelCase(result) as Promise<T>;
    }
}
