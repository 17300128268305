import { inject, injectable } from "inversify";
import { QueueStats, RealtimeStats } from "~/modules/stats";
import { FlexSdkError, ErrorCode } from "~/errors";
import { queueStatsProviderRTTI } from "~/modules/stats/stats.rtti";
import { rbacRTTI, Rbac } from "~/modules/rbac";
import { QueueStatsProvider } from "~/modules/stats/RealtimeStats/QueueStatsProvider/QueueStatsProvider";

@injectable()
export class RealtimeStatsImpl implements RealtimeStats {
    private readonly getQueueStats: QueueStatsProvider;

    private readonly rbac: Rbac;

    constructor(@inject(queueStatsProviderRTTI) getQueueStats: QueueStatsProvider, @inject(rbacRTTI) rbac: Rbac) {
        this.getQueueStats = getQueueStats;
        this.rbac = rbac;
    }

    private validatePermissions() {
        const isSupervisorOrAdmin: boolean = this.rbac.hasRole("supervisor") || this.rbac.hasRole("admin");
        if (!isSupervisorOrAdmin) {
            throw new FlexSdkError(ErrorCode.PermissionDeniedError);
        }
    }

    async getQueueStatsBySid(queueSid: string): Promise<QueueStats> {
        this.validatePermissions();
        const queueStats = await this.getQueueStats(queueSid);
        return queueStats;
    }
}
