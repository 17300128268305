


export enum LoggerName {
    Client = "flex:Client",
    Session = "flex:Session",
    Twilsock = "flex:Twilsock",
    Auth = "flex:Auth",
    Config = "flex:Config",
    Sync = "flex:Sync",
    SyncAdapter = "flex:SyncAdapter",
    QueueStats = "flex:QueueStats"
    
}




export enum SdkLoggerName {
    TwilioChat = "twilio-chat",
    TwilioSync = "twilio-sync",
    Twilsock = "twilsock"
}




export enum TelemetryLoggerName {
    TelemetryClient = "TelemetryClient",
    TelemetryProcessor = "TelemetryProcessor",
    TelemetrySdkClient = "TelemetrySdkClient"
}




export type InternalLoggerName = LoggerName | SdkLoggerName | TelemetryLoggerName;
