












import { inject, injectable } from "inversify";
import { FlexSdkError, ErrorCode } from "~/errors";
import { httpAdapterRTTI } from "~/backend/backend.rtti";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { convertDateStringValuesToDate } from "~/utils/processHttpAdapterResponse";

import { AccountConfigData } from "../model/accountConfigData";
import { PublicConfigResponse } from "../model/publicConfigResponse";

import { ConfigurationService } from "./configuration.serviceInterface";

@injectable()
export class ConfigurationServiceImpl implements ConfigurationService {
    private httpAdapter: HttpAdapter;

    constructor(@inject(httpAdapterRTTI) httpAdapter: HttpAdapter) {
        this.httpAdapter = httpAdapter;
    }

    




    public async fetchConfiguration(): Promise<AccountConfigData> {
        const response = await this.httpAdapter.get<AccountConfigData>(
            `https://flex-api[environment].twilio.com/v1/Configuration`,
            "jwe"
        );
        const processedResponse = convertDateStringValuesToDate<AccountConfigData>(response);
        return processedResponse;
    }

    






    public async fetchPublicConfiguration(
        queryParamName: "AccountSid" | "RuntimeDomain",
        queryParamValue: string
    ): Promise<PublicConfigResponse> {
        if (queryParamName === null || queryParamName === undefined) {
            throw new FlexSdkError(ErrorCode.InvalidParams, "Required parameter queryParamName was null or undefined.");
        }
        if (queryParamValue === null || queryParamValue === undefined) {
            throw new FlexSdkError(
                ErrorCode.InvalidParams,
                "Required parameter queryParamValue was null or undefined."
            );
        }
        const response = await this.httpAdapter.get<PublicConfigResponse>(
            `https://flex-api[environment].twilio.com/v1/Configuration/Public?${encodeURIComponent(
                String(queryParamName)
            )}=${encodeURIComponent(String(queryParamValue))}`,
            undefined
        );
        const processedResponse = convertDateStringValuesToDate<PublicConfigResponse>(response);
        return processedResponse;
    }

    





    public async updateConfiguration(accountConfigData: Partial<AccountConfigData>): Promise<AccountConfigData> {
        if (accountConfigData === null || accountConfigData === undefined) {
            throw new FlexSdkError(
                ErrorCode.InvalidParams,
                "Required parameter accountConfigData was null or undefined."
            );
        }
        const response = await this.httpAdapter.post<AccountConfigData>(
            `https://flex-api[environment].twilio.com/v1/Configuration`,
            "jwe",
            accountConfigData
        );
        const processedResponse = convertDateStringValuesToDate<AccountConfigData>(response);
        return processedResponse;
    }
}
