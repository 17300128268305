import { FlexSdkError } from "./FlexSdkError";
import { isValidErrorCode, ErrorCode } from "./ErrorCodes";

export interface ErrorResponse {
    code?: number;
    message?: string;
}

export function throwFlexSdkError(errorResponse: ErrorResponse) {
    if (!errorResponse.code || !isValidErrorCode(errorResponse.code)) {
        console.warn("Unknown error code"); 
    }

    const errorCode = errorResponse.code ? errorResponse.code : ErrorCode.Unknown;
    throw new FlexSdkError(errorCode, errorResponse.message);
}

export async function throwFlexSdkErrorFromResponse(response: Response, message?: string) {
    const errorResponse: ErrorResponse = await response.json();
    errorResponse.message = message || errorResponse.message;
    throwFlexSdkError(errorResponse);
}
