import cloneDeep from "lodash.clonedeep";
import { LoggerSettings } from "~/modules/logger";

const defaultLoggerSettings: LoggerSettings = {
    level: "ERROR"
};

export function getDefaultLoggerSettings(): LoggerSettings {
    return cloneDeep(defaultLoggerSettings);
}
