import { SyncMap as SyncSdkSyncMap, SyncMapItem as SyncSdkSyncMapItem } from "twilio-sync";
import { SyncMapItem, Subscribable, SubscribableOptions } from "~/modules/sync";
import { InternalError } from "~/errors";
import { createSubscribable } from "~/modules/sync/Subscribable/createSubscribable";

enum SubscribeEvent {
    ItemUpdated = "itemUpdated"
}

const defaultSubscribableOptions: SubscribableOptions = {
    mapKeysToCamelCase: true,
    dateFields: ["timestampUpdated"]
};

type SubscribableValue<T = object> = {
    options: SubscribableOptions;
    value: Subscribable<T>;
};

export class SyncMapItemImpl<T extends object = object> implements SyncMapItem<T> {
    private subscribable?: SubscribableValue<T>;

    private readonly subscribableSubItems: Map<string, SubscribableValue> = new Map();

    private readonly subscribers: Function[] = [];

    private readonly syncMap: SyncSdkSyncMap;

    private readonly itemKey: string;

    private _value: object;

    constructor(syncMap: SyncSdkSyncMap, mapItem: SyncSdkSyncMapItem) {
        this.syncMap = syncMap;
        this.itemKey = mapItem.key;
        this._value = mapItem.value;
    }

    get key(): string {
        return this.itemKey;
    }

    get value(): object {
        return this._value;
    }

    getSubscribable(options: Partial<SubscribableOptions> = {}): Subscribable<T> {
        if (!this.subscribable) {
            const finalOptions = { ...defaultSubscribableOptions, ...options };
            this.subscribable = {
                options: finalOptions,
                value: createSubscribable(this._value, finalOptions, this.subscribe, this.unsubscribe)
            };
        }

        return this.subscribable.value as Subscribable<T>;
    }

    getSubscribableForSubKey<K extends keyof T & string>(
        subKey: K,
        options: Partial<SubscribableOptions> = {}
    ): Subscribable<T[K]> {
        let subItem = this.subscribableSubItems.get(subKey);
        if (!subItem) {
            const subValue = this.getSubValue(subKey);
            const finalOptions = { ...defaultSubscribableOptions, ...options };
            const subscribable = createSubscribable(subValue, finalOptions, this.subscribe, this.unsubscribe);
            subItem = { options: finalOptions, value: subscribable };
            this.subscribableSubItems.set(subKey, subItem);
        }

        return subItem.value as Subscribable<T[K]>;
    }

    subscribe = (callback: Function) => {
        this.subscribers.push(callback);

        if (this.subscribers.length === 1) {
            this.syncMap.on(SubscribeEvent.ItemUpdated, this.itemUpdatedHandler);
        }
    };

    unsubscribe = (callback: Function) => {
        const subscriberIndex = this.subscribers.indexOf(callback);
        if (subscriberIndex < 0) {
            return;
        }

        this.subscribers.splice(subscriberIndex, 1);
        if (this.subscribers.length === 0) {
            this.syncMap.removeListener(SubscribeEvent.ItemUpdated, this.itemUpdatedHandler);
        }
    };

    private updateValue(value: object) {
        this._value = value;

        if (this.subscribable) {
            const newSubscribable = createSubscribable(
                value,
                this.subscribable.options,
                this.subscribe,
                this.unsubscribe
            );

            this.deleteRemovedSubKeys(this.subscribable.value, newSubscribable);
            Object.assign(this.subscribable.value, {
                ...newSubscribable
            });
        }

        this.subscribableSubItems.forEach((subValue, subKey) => {
            const updatedSubValue = this.getSubValue(subKey);
            const newSubItemSubscribable = createSubscribable(
                updatedSubValue,
                subValue.options,
                this.subscribe,
                this.unsubscribe
            );

            this.deleteRemovedSubKeys(subValue.value, newSubItemSubscribable);
            Object.assign(subValue.value, {
                ...newSubItemSubscribable
            });
        });
    }

    private deleteRemovedSubKeys(fromObject: object, newData: object) {
        const source = fromObject as { [k: string]: unknown };
        const removedKeys = Object.keys(source).filter((key) => !Object.prototype.hasOwnProperty.call(newData, key));

        removedKeys.forEach((keyToRemove) => {
            delete source[keyToRemove];
        });
    }

    private readonly itemUpdatedHandler = ({ item }: { item: SyncSdkSyncMapItem }) => {
        if (item.key === this.key) {
            this.updateValue(item.value);

            this.subscribers.forEach((fn) => fn());
        }
    };

    private isIndexObject(value: object, subscribedSubKey: string): value is { [key: string]: object } {
        return Object.prototype.hasOwnProperty.call(value, subscribedSubKey);
    }

    private getSubValue(subKey: string): object {
        if (this.isIndexObject(this.value, subKey)) {
            return this.value[subKey];
        }

        throw new InternalError(`Key ${subKey} does not exist in item ${this.itemKey}`);
    }
}
