import { Loglevel } from "~/modules/logger";
import { createLogEntry } from "./logentry/createLogEntry";
import { setContext } from "./logentry/setContext";
import { setData } from "./logentry/setData";
import { formatEntry } from "./logentry/formatEntry";

const factories = [createLogEntry, setContext, setData, formatEntry].reverse();

export const logEntryPlugin = (loglevel: Loglevel) => {
    factories.forEach((f) => {
        const originalFactory = loglevel.methodFactory;
        
        loglevel.methodFactory = f(originalFactory);
    });

    loglevel.setLevel(loglevel.getLevel());
    return loglevel;
};
