import { injectable, inject } from "inversify";
import { Emitter } from "./Emitter";
import { eventEmitterRTTI } from "~/modules/events/events.rtti";




@injectable()
export abstract class EventPublisher<E extends string | symbol> {
    @inject(eventEmitterRTTI) private readonly emitter: Emitter;

    public on(eventName: E, listener: (...args: unknown[]) => void) {
        this.emitter.on(eventName, listener);
        return this;
    }

    public removeAllListeners() {
        this.emitter.removeAllListeners();
        return this;
    }

    public removeListener(eventName: E, listener: (...args: unknown[]) => void) {
        this.emitter.removeListener(eventName, listener);
        return this;
    }

    protected emit(event: E, ...args: unknown[]) {
        return this.emitter.emit(event, ...args);
    }

    protected proxyEvent<T extends string | symbol>(from: EventPublisher<T> | Emitter, event: T, alias: E) {
        from.on(event, (...args: unknown[]) => {
            this.emit(alias, ...args);
        });
    }
}
