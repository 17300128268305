import { injectable, inject } from "inversify";
import { IdpConfig, TokenData, Authenticator } from "~/modules/auth";
import { throwFlexSdkErrorFromResponse, InternalError } from "~/errors";
import { assertNotEmptyString } from "~/utils/assert";
import { Logger, loggerFactoryRTTI, LoggerFactory, LoggerName } from "~/modules/logger";
import { EnvironmentConfig, environmentConfigRTTI } from "~/modules/config";
import { authenticatorDataContainerRTTI } from "~/modules/auth/auth.rtti";
import { AuthenticatorDataContainer } from "~/modules/auth/AuthenticatorDataContainer/AuthenticatorDataContainer";
import * as FederatedAuthHelper from "./FederatedAuthHelper";

@injectable()
export class FederatedAuth implements Authenticator {
    private readonly enviromentConfig: EnvironmentConfig;

    private readonly authenticatorDataContainer: AuthenticatorDataContainer;

    private readonly logger: Logger;

    constructor(
        @inject(environmentConfigRTTI) envConfig: EnvironmentConfig,
        @inject(authenticatorDataContainerRTTI) authenticatorDataContainer: AuthenticatorDataContainer,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory
    ) {
        this.enviromentConfig = envConfig;
        this.authenticatorDataContainer = authenticatorDataContainer;
        this.logger = getLogger(LoggerName.Auth);
    }

    public async getIdpUrl(config: IdpConfig): Promise<string> {
        assertNotEmptyString(config.redirectUrl, "redirect url");

        const headers = new Headers({
            "Content-Type": "application/json"
        });

        const payload = FederatedAuthHelper.getSSOLoginRequestBody(config);
        const authServiceUrl = this.enviromentConfig.authServiceUrl;
        const accountSid = this.authenticatorDataContainer.accountSid;
        const url = `${authServiceUrl}/${accountSid}/authenticate`;

        const response = await fetch(url, {
            headers,
            method: "POST",
            body: payload
        });

        if (!response.ok) {
            await throwFlexSdkErrorFromResponse(response);
        }

        const data = await response.json();
        if (!data.location) {
            this.logger.error("No redirect location from /authenticate request, data: ", data);
            throw new InternalError("Invalid response from /authenticate endpoint");
        }
        return data.location;
    }

    async validateToken(token: string): Promise<TokenData> {
        assertNotEmptyString(token, "token");

        const headers = new Headers({
            Authorization: `Basic ${btoa(`token:${token}`)})`,
            "Content-Type": "application/json"
        });

        const authServiceUrl = this.enviromentConfig.authServiceUrl;
        const accountSid = this.authenticatorDataContainer.accountSid;
        const url = `${authServiceUrl}/${accountSid}/Tokens/validate`;
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify({ token })
        });

        if (!response.ok) {
            await throwFlexSdkErrorFromResponse(response);
        }

        return response.json();
    }

    async refreshToken(token: string): Promise<string> {
        assertNotEmptyString(token, "token");

        const authServiceUrl = this.enviromentConfig.authServiceUrl;
        const accountSid = this.authenticatorDataContainer.accountSid;
        const url = `${authServiceUrl}/${accountSid}/Tokens/refresh`;

        const headers = new Headers({
            "Content-Type": "application/json"
        });

        const response = await fetch(url, {
            headers,
            method: "POST",
            body: JSON.stringify({ token })
        });

        if (!response.ok) {
            await throwFlexSdkErrorFromResponse(response, "Could not refresh token");
        }

        const payload = await response.json();
        return Promise.resolve(payload.token);
    }
}
