import { Logger } from "~/modules/logger";

const HTTP_STATUS_CODE_TOO_MANY_REQUESTS = 429;

export async function retry<T>(
    functionToRetry: Function,
    logger?: Logger,
    initialDelay: number = 100,
    maxAttempts: number = 10
): Promise<T> {
    let retryCount = 0;
    const maxDelay = 15 * 60 * 1000; 
    let errorToReturn;

    do {
        try {
            if (logger && retryCount > 0) {
                logger.debug("Retry attempt: ", retryCount);
            }

            
            return await functionToRetry();
        } catch (error) {
            
            
            const httpErrorCode = error.reply?.status?.code;
            if (httpErrorCode === HTTP_STATUS_CODE_TOO_MANY_REQUESTS) {
                if (retryCount >= maxAttempts) {
                    errorToReturn = error;
                    break;
                }
            } else {
                return Promise.reject(error);
            }
        }

        let delay = 1.5 ** retryCount * initialDelay;

        
        if (delay > maxDelay) {
            delay = maxDelay;
        }

        
        await new Promise((resolve) => setTimeout(resolve, delay));
    } while (retryCount++ < maxAttempts);

    return Promise.reject(errorToReturn);
}
