import { QueueStats, WorkerStats, TaskChannelStats, TasksByTime } from "~/modules/stats";
import { SyncMapItem, Subscribable, SubscribableOptions } from "~/modules/sync";
import { QueueStatsService } from "~/backend/generated/RealtimeQueueStats/api/queueStats.serviceInterface";
import { Metadata } from "~/backend/generated/RealtimeQueueStats/model/metadata";
import { ErrorCode, FlexSdkError } from "~/errors";

export class QueueStatsImpl implements QueueStats {
    readonly sid: string;

    readonly friendlyName: string;

    readonly tasks: TasksByTime;

    readonly dateUpdated: Date;

    private readonly queueStatsService: QueueStatsService;

    constructor(
        queueSid: string,
        queueStatsService: QueueStatsService,
        tasksByTime: TasksByTime,
        metadataMapItem: SyncMapItem<Metadata>
    ) {
        this.sid = queueSid;
        this.queueStatsService = queueStatsService;
        this.tasks = tasksByTime;

        const metadata = metadataMapItem.getSubscribable();
        this.friendlyName = metadata.queueFriendlyName || "";
    }

    async getWorkerStats(): Promise<Subscribable<WorkerStats>> {
        try {
            const workerStats = await this.queueStatsService.getWorkerStats(this.sid);
            const subscribableOptions: SubscribableOptions<keyof WorkerStats> = {
                mapKeysToCamelCase: true,
                dateFields: ["timestampUpdated"]
            };
            return workerStats.getSubscribable(subscribableOptions);
        } catch (error) {
            if (error.code === ErrorCode.SyncNameNotFoundError) {
                throw new FlexSdkError(ErrorCode.NotFound, `Worker statistics of the queue: ${this.sid}`, error);
            }
            throw error;
        }
    }

    async getTaskChannelStats(): Promise<Subscribable<TaskChannelStats>> {
        return Promise.reject(new Error("Not implemented"));
    }

    async getAllTaskChannelStats(): Promise<Subscribable<TaskChannelStats>[]> {
        return Promise.reject(new Error("Not implemented"));
    }
}
