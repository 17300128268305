import { assertNotEmptyString } from "~/utils/assert";
import { Subscribable, SubscribableOptions } from "~/modules/sync";
import { TasksByTime, CurrentTasksStats, HistoricalTasksStats, TimeSpan } from "~/modules/stats";

import { QueueStatsService } from "~/backend/generated/RealtimeQueueStats/api/queueStats.serviceInterface";
import { ErrorCode, FlexSdkError } from "~/errors";

const MAP_ITEM_PROPERTY_KEY_QUEUE = "queue";

export class TasksByTimeImpl implements TasksByTime {
    private readonly queueStatsService: QueueStatsService;

    private readonly queueSid: string;

    constructor(queueSid: string, queueStatsService: QueueStatsService) {
        this.queueSid = queueSid;
        this.queueStatsService = queueStatsService;
    }

    async getCurrentTasks(): Promise<Subscribable<CurrentTasksStats>> {
        try {
            const item = await this.queueStatsService.getCurrentTasksStats(this.queueSid);
            const subscribableOptions: SubscribableOptions<keyof CurrentTasksStats> = {
                mapKeysToCamelCase: true,
                dateFields: ["timestampUpdated", "longestTaskWaitingFrom"]
            };
            const subscribable = item.getSubscribableForSubKey(MAP_ITEM_PROPERTY_KEY_QUEUE, subscribableOptions);
            return subscribable;
        } catch (error) {
            if (error.code === ErrorCode.SyncNameNotFoundError) {
                throw new FlexSdkError(
                    ErrorCode.NotFound,
                    `Statistics for current tasks of the queue: ${this.queueSid}`,
                    error
                );
            }
            throw error;
        }
    }

    async getForPeriod(period: TimeSpan): Promise<Subscribable<HistoricalTasksStats>> {
        assertNotEmptyString(period, "period");
        try {
            const item = await this.queueStatsService.getHistoricalStats(this.queueSid, period);
            const subscribableOptions: SubscribableOptions<keyof HistoricalTasksStats> = {
                mapKeysToCamelCase: true,
                dateFields: ["timestampUpdated"]
            };
            const subscribable = item.getSubscribableForSubKey(MAP_ITEM_PROPERTY_KEY_QUEUE, subscribableOptions);
            return subscribable;
        } catch (error) {
            if (error.code === ErrorCode.SyncNameNotFoundError) {
                throw new FlexSdkError(
                    ErrorCode.NotFound,
                    `Historical statistics for the period ${period} of the queue: ${this.queueSid}`,
                    error
                );
            }
            throw error;
        }
    }
}
