












import { inject, injectable } from "inversify";
import { twilsockAdapterRTTI } from "~/backend/backend.rtti";
import { TwilsockAdapter } from "~/backend/TwilsockAdapter/TwilsockAdapter";
import { TwilsockResult } from "~/modules/websocket";

import { Events } from "../model/events";

import { TelemetryService } from "./telemetry.serviceInterface";

@injectable()
export class TelemetryServiceImpl implements TelemetryService {
    private basePath: string = "http://flex-sdk.twilio.com/v1";
    private twilsockAdapter: TwilsockAdapter;

    constructor(@inject(twilsockAdapterRTTI) twilsockAdapter: TwilsockAdapter) {
        this.twilsockAdapter = twilsockAdapter;
    }

    




    public async postTelemetryEvents(events: Events): Promise<TwilsockResult<void>> {
        if (events === null || events === undefined) {
            throw new Error("Required parameter events was null or undefined when calling postTelemetryEvents.");
        }

        const response = await this.twilsockAdapter.post<void>(`${this.basePath}/Telemetry/Events`, events);
        return response;
    }
}
