import { injectable, inject } from "inversify";
import { AccountConfigKeys, AccountConfigData, AccountConfig } from "~/modules/config";
import { configDataContainerRTTI, accountConfigUpdaterRTTI } from "~/modules/config/config.rtti";
import { assertKeyInObject } from "~/utils/assert";
import { AccountConfigUpdater } from "~/modules/config/AccountConfig/AccountConfigUpdater";
import { ConfigDataContainer } from "~/modules/config/AccountConfig/ConfigDataContainer";

@injectable()
export class AccountConfigStore implements AccountConfig {
    private readonly dataContainer: ConfigDataContainer<AccountConfigData>;

    private readonly accountConfigUpdater: AccountConfigUpdater;

    private currentUpdateConfigPromise: Promise<AccountConfigData> | undefined;

    constructor(
        @inject(configDataContainerRTTI) dataContainer: ConfigDataContainer<AccountConfigData>,
        @inject(accountConfigUpdaterRTTI) updater: AccountConfigUpdater
    ) {
        this.dataContainer = dataContainer;
        this.accountConfigUpdater = updater;
    }

    get<K extends AccountConfigKeys>(key: K): AccountConfigData[K] {
        return this.dataContainer.get()[key];
    }

    async set(config: Partial<AccountConfigData>): Promise<void> {
        const data = this.dataContainer.get();

        
        const { accountSid, ...dataWithoutAccountSid } = data; 
        Object.keys(config).map((key: string) => assertKeyInObject(key, dataWithoutAccountSid));

        
        const updateConfigCall = () => this.accountConfigUpdater(accountSid, config);
        const updatedConfigData = await this.updateInCallOrder(updateConfigCall);
        this.dataContainer.set(updatedConfigData);

        return Promise.resolve();
    }

    updateInCallOrder(updateConfigCall: () => Promise<AccountConfigData>) {
        if (this.currentUpdateConfigPromise) {
            
            
            this.currentUpdateConfigPromise = this.currentUpdateConfigPromise.then(() => {
                return updateConfigCall();
            });
        } else {
            this.currentUpdateConfigPromise = updateConfigCall();
        }

        return this.currentUpdateConfigPromise;
    }
}
