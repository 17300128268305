import { FlexSdkError, ErrorCode } from "~/errors";

export function assertArray<T>(value: Array<T>, name: string): asserts value is Array<T> {
    if (!Array.isArray(value)) {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${name}, expected an array`);
    }
}

export function assertBoolean(value: boolean, name: string): asserts value is boolean {
    if (typeof value !== "boolean") {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${name}, expected a boolean`);
    }
}

export function assertDefined<T>(value: T, name: string): asserts value is T {
    if (typeof value === "undefined") {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${name}, expected a defined value`);
    }
}

export function assertNumber(value: number, name: string): asserts value is number {
    if (typeof value !== "number" || Number.isNaN(value)) {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${name}, expected a number`);
    }
}

export function assertObject<T>(value: T, name: string): asserts value is T {
    if (typeof value !== "object" || value === null || Array.isArray(value)) {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${name}, expected an object`);
    }
}

export function assertString(value: string, name: string): asserts value is string {
    if (typeof value !== "string") {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${name}, expected a string`);
    }
}

export function assertNotEmptyString(value: string, name: string): asserts value is string {
    assertString(value, name);
    if (value.trim() === "") {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${name}, expected a string which is not empty`);
    }
}

export function assertKeyInObject<T>(key: string, object: T) {
    if (!(key in object)) {
        throw new FlexSdkError(ErrorCode.InvalidParams, `${key} is not a valid key`);
    }
}
