import { ErrorCode, getMessage } from "./ErrorCodes";





export class FlexSdkError extends Error {
    private readonly _details: string | undefined;

    private readonly _errorCode: ErrorCode;

    private readonly _source: Error | undefined;

    constructor(errorCode: ErrorCode, details?: string, source?: Error) {
        const detailsMessage = details !== undefined ? `: ${details}` : "";
        const message = `${getMessage(errorCode)}${detailsMessage}`;
        super(message);
        this._errorCode = errorCode;
        this._source = source;
        this._details = details;

        Object.setPrototypeOf(this, FlexSdkError.prototype);
    }

    





    public get code(): ErrorCode {
        return this._errorCode;
    }

    





    public get details(): string | undefined {
        return this._details;
    }

    



    public get source(): Error | undefined {
        return this._source;
    }
}
