



export enum ErrorCode {
    


    Forbidden = 403,
    


    InvalidParams = 400,
    


    InvalidState = 409,
    


    NotFound = 404,
    


    SDK = 500,
    


    Unknown = 501,
    


    UntrustedDomain = 70002,
    


    InvalidCertificate = 70251,
    


    InvalidSSO = 45212,
    


    InvalidAccountSid = 45213,
    


    InvalidAccessToken = 20101,
    


    AccessTokenExpired = 20104,
    


    ValidationError = 45004,
    


    AuthorizationError = 45003,
    


    PermissionDeniedError = 20003,
    


    UnsupportedMediaError = 20415,
    


    TwilsockConnectionError = 51216,
    


    SyncAccessForbiddenError = 54007,
    


    SyncMapNotFoundError = 54200,
    


    SyncMapItemNotFoundError = 54201,
    


    SyncInvalidMapItemDataError = 54206,
    


    SyncInvalidMapItemKeyError = 54209,
    


    SyncNameNotFoundError = 54300,
    


    SyncNameAlreadyExistsError = 54301,
    


    SyncInvalidNameError = 54302,
    


    SyncConnectionError = 45307,
    


    TooManyRequests = 20429
}

type ErrorInfo = string; 
type ErrorInfoMap = { [TKey in ErrorCode]: ErrorInfo };


const errorDetails: ErrorInfoMap = {
    [ErrorCode.Forbidden]: "User is not authorized to perform the requested operation",
    [ErrorCode.InvalidParams]: "Invalid parameters",
    [ErrorCode.InvalidState]: "Invalid state",
    [ErrorCode.NotFound]: "Resource not found",
    [ErrorCode.TooManyRequests]: "Too many requests",
    [ErrorCode.SDK]: "Generic error from inner SDK",
    [ErrorCode.Unknown]: "Unknown error",
    [ErrorCode.UntrustedDomain]: "Validation error: invalid domain",
    [ErrorCode.InvalidCertificate]: "Validation error: invalid certificate",
    [ErrorCode.InvalidSSO]: "Validation error: invalid SSO",
    [ErrorCode.InvalidAccountSid]: "Validation error: invalid account sid",
    [ErrorCode.InvalidAccessToken]: "Invalid Access Token",
    [ErrorCode.AccessTokenExpired]: "Access Token expired or expiration date invalid",
    [ErrorCode.ValidationError]: "Validation error: request body validation fails",
    [ErrorCode.AuthorizationError]: "Attempt to access resource where access is not allowed for given credentials",
    [ErrorCode.PermissionDeniedError]: "You lack permission to the resource and method you requested",
    [ErrorCode.UnsupportedMediaError]: "The server does not support the media type transmitted in the request",
    [ErrorCode.TwilsockConnectionError]: "Twilsock connection error",
    [ErrorCode.SyncAccessForbiddenError]: "Sync access forbidden for identity",
    [ErrorCode.SyncMapNotFoundError]: "Sync map not found",
    [ErrorCode.SyncInvalidMapItemDataError]: "Invalid sync map item data",
    [ErrorCode.SyncInvalidMapItemKeyError]: "Invalid sync map item key",
    [ErrorCode.SyncMapItemNotFoundError]: "Sync map item not found",
    [ErrorCode.SyncNameNotFoundError]: "Sync unique name not found",
    [ErrorCode.SyncNameAlreadyExistsError]: "Sync unique name already exists",
    [ErrorCode.SyncInvalidNameError]: "Sync invalid unique name",
    [ErrorCode.SyncConnectionError]: "Sync connection error"
};

export function isValidErrorCode(error: number): boolean {
    const errorFound = Object.values(ErrorCode).find((code: number) => code === error);
    return !!errorFound;
}

export function getMessage(error: ErrorCode): string {
    return isValidErrorCode(error) ? errorDetails[error] : "Error";
}
